import { environment } from '@environments/environment';
import { Action, ActionReducer, MetaReducer } from '@ngrx/store';
import * as Sentry from '@sentry/browser';
import { Event } from '@sentry/types';
import { AppStateModel } from '@shared/models/app-state.model';
import { Fullstory } from '@shared/models/fullstory.model';
import { logoutAction, resetStoreAction } from '@shared/store/auth/auth.action';

declare global {
  interface Window {
    FS: Fullstory;
    dataLayer: any[];
  }
}

Sentry.init({
  dsn: environment.sentryDSN,
  denyUrls: [/localhost/],
  beforeSend: (event, hint): Event => {
    const error = hint.originalException as Error;
    event.extra = event.extra || {};

    // Stop sending "invalid password" and "no user record" events to Sentry
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (error.code === 'auth/wrong-password' || error.code === 'auth/user-not-found') {
      return null;
    }

    if ('FS' in window && 'getCurrentSessionURL' in window.FS && 'event' in window.FS) {
      event.extra.fullstory = window.FS.getCurrentSessionURL(true) || 'current session URL API not ready';

      window.FS.event('Application Error', {
        name: error.name,
        message: error.message,
        stack: error.stack,
        sentryEventId: hint.event_id,
      });
    }

    return event;
  },
});

export function resetState(
  reducer: ActionReducer<AppStateModel | Partial<AppStateModel>>,
): ActionReducer<AppStateModel | Partial<AppStateModel>> {
  return (state: AppStateModel, action: Action): AppStateModel | Partial<AppStateModel> => {
    switch (action.type) {
      case logoutAction.type:
      case resetStoreAction.type:
        return reducer({ remoteConfig: state.remoteConfig }, action);

      default:
        return reducer(state, action);
    }
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const metaReducers: MetaReducer<any>[] = [resetState];
