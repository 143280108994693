import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PaginationModel } from '@shared/components/table';
import { ButtonModule } from 'primeng/button';
import { InputNumberModule } from 'primeng/inputnumber';

@Component({
  selector: 'ap-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
  imports: [InputNumberModule, FormsModule, ButtonModule],
})
export class PaginatorComponent {
  @Input() numberOfPages = 0;
  @Input() selectedPage = 0;
  @Input() pageSize = 10;
  @Input() numberOfEntries;

  @Output() onPage = new EventEmitter<PaginationModel>();

  readonly DOTS_DISPLAY_MARGIN = 3;

  readonly pageSizeOptions = [10, 20, 50, 100];

  selectedGoToPage: number = null;

  get previousPage(): number {
    return this.selectedPage - 1;
  }

  get previousPreviousPage(): number {
    return this.selectedPage - 2;
  }

  get nextPage(): number {
    return this.selectedPage + 1;
  }

  get nextNextPage(): number {
    return this.selectedPage + 2;
  }

  get selectedPageSize(): number {
    return this.pageSize;
  }

  get normalisedNumberOfPages(): number {
    return this.numberOfPages - 1;
  }

  setPageSize(size: number) {
    this.pageSize = size;
    this.onPage.emit({ pageNumber: 0, pageSize: this.pageSize });
  }

  selectPage(newPageIndex: number): void {
    if (newPageIndex < 0) {
      newPageIndex = 0;
    }
    if (newPageIndex > this.numberOfPages - 1) {
      newPageIndex = this.numberOfPages - 1;
    }
    this.selectedPage = newPageIndex;
    this.onPage.emit({ pageNumber: this.selectedPage, pageSize: this.pageSize });
    this.selectedGoToPage = null;
  }

  displayedPageNumber(pageNumber: number): number {
    return pageNumber + 1;
  }

  goToPageKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      (event.currentTarget as HTMLElement).blur();
      this.selectPage(this.selectedGoToPage - 1);
    }
  }

  onGoToPageInput({ value }: { value: any }): void {
    if (typeof value === 'number' || value === null) {
      this.selectedGoToPage = value;
    }
  }
}
