import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SortType } from '@shared/components/table';

@Component({
  selector: 'ap-sort-icon',
  templateUrl: './sort-icon.component.html',
  styleUrls: ['./sort-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [],
})
export class SortIconComponent {
  @Input() sort: any;
  @Input() field: string;

  SortType = SortType;
}
