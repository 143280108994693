import { routerReducer } from '@ngrx/router-store';
import { ActionReducerMap, Store, createAction, props } from '@ngrx/store';
import { CompanyEffect } from '@platform/company/store/company.effect';
import { companyReducer } from '@platform/company/store/company.reducer';
import { DashboardEffect } from '@platform/dashboard/store/dashboard.effect';
import { dashboardReducer } from '@platform/dashboard/store/dashboard.reducer';
import { isEqual } from '@shared-lib/utils/isEqual';
import { PlatformEffect } from '@shared/store/platform/platform.effects';
import { platformReducer } from '@shared/store/platform/platform.reducer';
import { tableReducer } from '@shared/store/table/table.reducer';
import { Observable, distinctUntilChanged } from 'rxjs';
import { AppStateModel } from '../models/app-state.model';
import { AuthEffect } from './auth/auth.effect';
import { authReducer } from './auth/auth.reducer';
import { RemoteConfigEffect } from './remote-config/remote-config.effect';
import { remoteConfigReducer } from './remote-config/remote-config.reducer';
import { RouteEffect } from './route/route.effect';
import { UserEffect } from './user/user.effect';
import { userReducer } from './user/user.reducer';

export const EFFECTS = [AuthEffect, CompanyEffect, DashboardEffect, PlatformEffect, RemoteConfigEffect, RouteEffect, UserEffect];

export const REDUCERS: ActionReducerMap<AppStateModel> = {
  auth: authReducer,
  company: companyReducer,
  currentUser: userReducer,
  dashboard: dashboardReducer,
  platform: platformReducer,
  remoteConfig: remoteConfigReducer,
  route: routerReducer,
  tables: tableReducer,
};

export type CustomComponentStoreState = object & {
  lastCalledAction?: string;
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const updateComponentState = (type: string) => createAction(type, props<{ componentState: CustomComponentStoreState }>());

export const linkToGlobalState = (
  componentState$: Observable<CustomComponentStoreState>,
  componentName: string,
  globalStore: Store,
): void => {
  componentState$.pipe(distinctUntilChanged((prev, next) => isEqual(prev, next))).subscribe((componentState) => {
    globalStore.dispatch(
      updateComponentState(`[${componentName}Store] ${componentState.lastCalledAction ?? 'Update Action'}`)({
        componentState,
      }),
    );
  });
};
