import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppStateModel } from '@shared/models/app-state.model';
import { $configMaintenanceDate } from '@shared/store/remote-config/remote-config.selector';
import { Observable } from 'rxjs';

@Component({
  selector: 'ap-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AsyncPipe],
})
export class MaintenanceComponent {
  readonly maintenanceDate$: Observable<string> = this.store.pipe(select($configMaintenanceDate));

  constructor(private readonly store: Store<AppStateModel>) {}
}
