import { HTTP_AP_UpdateCompany_ForDetails_Request } from '@api-new/companyservice';
import { addressFromToStringAdapter } from '@shared-lib/components/forms/address-form/adapters/address-form.adapter';
import { UpdateCompanyFormRawValues } from '../utils/formUtils';

export const updateCompanyFormToHTTPRequestAdapter = (
  data: UpdateCompanyFormRawValues,
  companyId: string,
): HTTP_AP_UpdateCompany_ForDetails_Request => {
  if (!data.customClientPortalDomain) {
    delete data.customClientPortalDomain;
  }
  return {
    ...data,
    companyId,
    address: addressFromToStringAdapter(data.address),
    registeredAddress: addressFromToStringAdapter(data.registeredAddress),
    fcaNumber: data.fcaNumber.toString(),
    primaryLightColor: data.primaryLightColor.replace('#', ''),
    secondaryColor: data.secondaryColor.replace('#', ''),
    primaryColor: data.primaryColor.replace('#', ''),
  };
};
