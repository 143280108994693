import { A11yModule } from '@angular/cdk/a11y';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormGroupDirective, FormsModule } from '@angular/forms';
import { FormControlToNamePipe } from '@shared/pipes/form-control-name/form-control-to-name';
import { SafeStylePipe } from '@shared/pipes/safe-style/safe-style.pipe';
import { ButtonModule as PrimengButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule as CheckboxModulePrimeNG } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { DialogService } from 'primeng/dynamicdialog';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule as MultiselectModulePrimeNG } from 'primeng/multiselect';
import { PanelMenuModule } from 'primeng/panelmenu';
import { PasswordModule } from 'primeng/password';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RippleModule } from 'primeng/ripple';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SkeletonModule } from 'primeng/skeleton';
import { SplitButtonModule } from 'primeng/splitbutton';
import { StepsModule } from 'primeng/steps';
import { TableModule } from 'primeng/table';
import { TabMenuModule } from 'primeng/tabmenu';
import { TagModule } from 'primeng/tag';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { EnumToTitleCasePipe } from './pipes/enum-to-titlecase.pipe';

@NgModule({
  imports: [
    // -------------------------------------------------------------------
    CommonModule,
    FormsModule,
    ClipboardModule,
    OverlayModule,
    SafeStylePipe,
    A11yModule,
    // PrimeNG modules
    InputSwitchModule,
    InputTextModule,
    MultiselectModulePrimeNG,
    PasswordModule,
    PrimengButtonModule,
    RippleModule,
    SelectButtonModule,
    SplitButtonModule,
    StepsModule,
    TableModule,
    TabMenuModule,
    PanelMenuModule,
    RadioButtonModule,
    SkeletonModule,
    ProgressSpinnerModule,
    CalendarModule,
    CheckboxModulePrimeNG,
    InputNumberModule,
    TagModule,
    DialogModule,
    ToastModule,
    TooltipModule,
    // Pipes
    EnumToTitleCasePipe,
    FormControlToNamePipe,
  ],
  exports: [
    FormsModule,
    // Pipes
    EnumToTitleCasePipe,
    FormControlToNamePipe,
    // PrimeNG modules
    InputSwitchModule,
    InputTextModule,
    MultiselectModulePrimeNG,
    PasswordModule,
    PrimengButtonModule,
    RippleModule,
    SelectButtonModule,
    SplitButtonModule,
    StepsModule,
    TableModule,
    TabMenuModule,
    PanelMenuModule,
    RadioButtonModule,
    SkeletonModule,
    ProgressSpinnerModule,
    CalendarModule,
    CheckboxModulePrimeNG,
    InputNumberModule,
    TagModule,
    DialogModule,
    TooltipModule,
  ],
  providers: [DialogService, FormGroupDirective],
})
export class SharedModule {}
