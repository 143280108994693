export enum TablesEnum {
  CLIENT_CASE_TABLE = 'clientCaseTable',
  CLIENT_TABLE = 'clientTable',
  COMPANY_TABLE = 'companyTable',
  IMPORT_TABLE = 'importTable',
  LEAD_CASE_TABLE = 'leadCaseTable',
  LENDER_TABLE = 'lenderTable',
  LENDER_GROUP_TABLE = 'lenderGroupTable',
  MORTGAGE_CLOSED_CASE_TABLE = 'mortgageClosedCaseTable',
  MORTGAGE_DOC_AI_TABLE = 'mortgageDocAiTable',
  MORTGAGE_PIPELINE_CASE_TABLE = 'mortgagePipelineCaseTable',
  MORTGAGE_TABLE = 'mortgageTable',
  OPPORTUNITY_APPROVAL_REQUIRED_TABLE = 'opportunityApprovalRequiredTable',
  OPPORTUNITY_TABLE = 'opportunityTable',
  OUTCODE_TO_REGION_TABLE = 'outcodeToRegionTable',
  PRE_COMPLETION_TABLE = 'preCompletionTable',
  REFERRAL_CODE_TABLE = 'referralCodeTable',
  TINY_URLS_TABLE = 'tinyUrlsTable',
  USER_TABLE = 'userTable',
  AFFILIATE_TABLE = 'affiliateTable',
  PROPERTY_TABLE = 'propertyTable',
  MORTGAGE_PRODUCT_TABLE = 'mortgageProductTable',
}
