<div class="list-header">
  @if (header) {
    <h1>{{ header }}</h1>
  }
  <ng-content select="[custom-title]" />
  <div class="redirects">
    <ng-content select="[redirects]" />
  </div>
  @if (hasFilter) {
    <div class="filter-actions">
      <p-button
        type="button"
        [label]="isFilterExpanded ? 'Hide filter' : 'Show filter'"
        styleClass="p-button-outlined"
        icon="icon-filter-outline"
        iconPos="left"
        [badge]="numberOfAppliedFilters && numberOfAppliedFilters?.toString()"
        badgeSeverity="warn"
        (click)="toggleFilterExpanded()" />
      <button
        class="p-button-outlined"
        label="Reset filter"
        pButton
        type="button"
        [disabled]="!numberOfAppliedFilters"
        (click)="emitResetFilter()"></button>
    </div>
  }
</div>
<div [expanded]="isFilterExpanded">
  <div #filter>
    <ng-content select="[filter]" />
  </div>
</div>
